/* Reset */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Arial', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Hero Section */
.hero {
  background-color: lightblue;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.hero-content h1 {
  font-size: 3.5em;
  margin: 0;
}

.hero-content p {
  font-size: 1.5em;
}

/* About Section */
/* About Section - 中央揃え & 背景横幅いっぱい */
#about {
  padding: 60px 20px;
  background-color: #f9f9f9;
  /* 背景色 */
  text-align: center;
  /* タイトルと内容を中央揃え */
}

#about h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

#about p {
  font-size: 1.2em;
  line-height: 1.6;
  margin: 0 auto 40px auto;
  /* 下に余白を追加 */
  max-width: 800px;
  /* テキストの横幅を制限して読みやすく */
}

#about ul {
  list-style-type: none;
  /* デフォルトのマーカーを非表示 */
  padding: 0;
  margin: 0 auto;
  max-width: 600px;
  /* リスト全体の横幅を制限 */
  text-align: left;
  /* リスト項目自体は左揃え */
}

#about ul li {
  font-size: 1.1em;
  line-height: 1.6;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  /* 下線で項目を区切る */
}

#about ul li:last-child {
  border-bottom: none;
  /* 最後の項目の下線を削除 */
}

#about ul li strong {
  color: #007BFF;
  /* 強調部分を青色に変更 */
}



/* Projects Section */
#projects {
  padding: 60px 20px;
  text-align: center;
}

.project-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.project {
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 30%;
}

.project:hover {
  transform: translateY(-10px);
}

.project h3 {
  margin-bottom: 15px;
}

.project p {
  font-size: 1em;
}

.btn {
  display: inline-block;
  padding: 10px 15px;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
  text-decoration: none;
}

.btn:hover {
  background-color: #0056b3;
}

/* Skills Section */
#skills {
  padding: 60px 20px;
  background-color: #f0f0f0;
  text-align: center;
}

.skills-list {
  list-style: none;
  padding: 0;
}

.skills-list li {
  display: inline-block;
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
}

/* Career Section */
#career {
  padding: 60px 20px;
  background-color: #f0f0f0;
  /* 背景色 */
  text-align: left;
  /* 内容を左揃え */
  width: 100%;
  /* 背景を画面幅いっぱいに */
  box-sizing: border-box;
  /* パディングを含む幅計算 */
}

#career h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
  /* タイトルを中央揃え */
}

#career ul {
  list-style-type: none;
  /* マーカーを非表示 */
  padding: 0;
  margin: 0 auto;
  max-width: 1000px;
  /* 横幅を広げて文字が詰まらないように */
}

#career ul li {
  font-size: 1.2em;
  /* 文字を少し大きく */
  line-height: 1.8;
  /* 行間を広げる */
  margin-bottom: 20px;
  /* 項目間の余白を拡大 */
  border-left: 4px solid #007BFF;
  /* 左側のアクセントライン */
  padding-left: 15px;
  /* 内容とラインの余白 */
}

#career ul li strong {
  color: #007BFF;
  /* 日付部分を青色で強調 */
  font-weight: bold;
}




button {
  padding: 10px 20px;
  font-size: 1.2em;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Footer */
footer {
  background-color: #282c34;
  color: white;
  text-align: center;
  padding: 20px 0;
  width: 100%;
  margin-top: auto;
}